.general-wrapper,
.building-wrapper {
  padding: 35px; }

.general-card {
  margin: 0 auto 65px;
  padding: 20px 35px 35px;
  background: white;
  max-width: 1024px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.12);
  text-align: left; }
  .general-card .general-quick-start,
  .general-card .general-faq {
    padding-top: 10px; }
    .general-card .general-quick-start h2,
    .general-card .general-faq h2 {
      padding-bottom: 10px; }
    .general-card .general-quick-start .general-header,
    .general-card .general-faq .general-header {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #333;
      height: 30px;
      display: flex;
      justify-content: flex-start;
      margin-top: 15px;
      margin-bottom: -15px; }
    .general-card .general-quick-start .general-body,
    .general-card .general-faq .general-body {
      display: flex;
      flex-direction: column;
      padding: 10px 0; }
      .general-card .general-quick-start .general-body p,
      .general-card .general-faq .general-body p {
        margin: 7px 10px; }

.general-download-buttons {
  display: flex;
  flex-direction: column;
  float: right;
  justify-content: space-between;
  width: 14%;
  min-width: 180px;
  font-size: 18px;
  color: white;
  height: 100px;
  margin: auto;
  margin-bottom: 20px;
  margin-left: -100%; }
  .general-download-buttons a {
    color: inherit; }

.general-button-download-csv {
  width: 100%;
  background: #eeedee;
  line-height: 40px;
  text-align: center;
  transition: 0.2s all ease; }
  .general-button-download-csv.active {
    background: #98bf47; }

.general-button-download-csv:hover {
  cursor: no-drop; }

.general-button-download-csv.active:hover {
  background-color: #3C414B;
  cursor: pointer; }
