.building-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 4px;
  margin: auto; }
  .building-header .building-title {
    font-size: 24px;
    line-height: 36px; }
  .building-header .building-buttons {
    display: flex;
    justify-content: space-between;
    width: 25%;
    min-width: 140px;
    color: white;
    font-size: 18px;
    text-align: center; }
  .building-header .building-cancel,
  .building-header .building-save {
    padding-top: 10px;
    height: 30px;
    width: 48%;
    border: #333;
    background-color: #3C414B;
    color: inherit;
    cursor: pointer;
    transition: all 0.2s ease-in-out; }
  .building-header .building-save {
    background-color: #98bf47; }
  .building-header .building-cancel:hover {
    background-color: #f05910; }
  .building-header .building-save:hover {
    background-color: #3C414B; }

.building-error-link {
  padding: 0px 10px 20px; }
  .building-error-link a {
    color: #4D688C;
    text-decoration: underline;
    font-style: italic; }
