.common-button {
  cursor: pointer;
  color: #000000;
  font-family: "Proxima Nova";
  font-size: 15px;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 13px;
  border: 1px solid transparent; }
  .common-button:hover, .common-button.selected, .common-button:active {
    box-sizing: border-box;
    padding: 10px 12px;
    font-weight: bold; }
  .common-button.selected, .common-button:active {
    border: 1px solid #CACACA;
    background-color: #FFFFFF; }
