.header-background {
  background-size: cover;
  background-position: center bottom;
  background-image: url("../../images/header.jpg");
  color: white;
  height: 300px; }

.header-wrapper {
  background-color: rgba(69, 75, 84, 0.8);
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.header-title {
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  padding-top: 6px; }

.header-subtitle {
  margin-top: 20px;
  color: #D2D2D2;
  font-weight: 600;
  font-size: 16px;
  align-items: center; }
