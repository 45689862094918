a.help-link {
  text-decoration: none;
  color: #eeedee; }

.help-wrapper {
  position: absolute;
  top: 28px;
  left: 20px;
  background-color: #3C414B;
  font-size: 28px;
  font-weight: 600;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid white;
  cursor: pointer;
  transition: all 0.2s ease-in-out; }

.help-wrapper:hover {
  background: #98bf47; }
