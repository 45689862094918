.navigation-bar {
  height: 65px;
  width: 100%;
  display: block;
  background-color: #3C414B; }

ul {
  list-style: none;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center; }

li {
  width: 200px;
  height: 40px;
  padding-top: 25px;
  margin: auto;
  text-align: center;
  position: relative;
  color: #98bf47;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.7px;
  transition: 0.3s all;
  cursor: pointer; }

.bar-underline {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: #98bf47;
  transition: all 0.3s ease-in-out 0s; }

li:hover ~ .bar-underline {
  background-color: aliceblue; }

a {
  text-decoration: none; }
