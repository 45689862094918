.buildings-wrapper,
.building-wrapper {
  padding: 35px; }

.buildings-table,
.building-layout {
  margin: 0 auto 65px;
  padding: 20px 35px 35px;
  background: white;
  max-width: 1200px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.12);
  text-align: left; }

.buildings-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.new-building-button {
  width: 14%;
  min-width: 120px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  height: 40px;
  margin-bottom: 20px; }

.create-new-building {
  width: 100%;
  background: #98bf47;
  line-height: 40px;
  text-align: center;
  transition: 0.2s all ease; }

.create-new-building:hover {
  background-color: #3C414B; }

.buildings-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #333;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  border-bottom: #333 solid 2px; }

.buildings-body {
  display: flex;
  flex-direction: column; }
  .buildings-body .buildings-none {
    margin: auto;
    padding-top: 20px;
    color: #676767;
    font-style: italic; }

.buildings-table-row {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #eeedee;
  cursor: pointer;
  height: 45px;
  transition: all 0.3s ease-in-out 0s; }

.buildings-table-row:hover {
  background-color: #9C9D9F;
  transition: all 0.3s ease-in-out 0s; }

.buildings-display-name {
  width: 32%;
  margin: auto 10px;
  color: black; }

.buildings-short-name {
  width: 18%; }

.buildings-commodities {
  width: 16%;
  text-align: center; }

.buildings-eui-ready {
  width: 8%;
  text-align: center; }

.buildings-type {
  width: 10%; }

.buildings-active {
  width: 8%;
  text-align: center; }

.buildings-hot-water {
  width: 8%;
  text-align: center; }

.buildings-short-name,
.buildings-commodities,
.buildings-eui-ready,
.buildings-type,
.buildings-active,
.buildings-hot-water {
  margin: auto 0;
  text-decoration: none;
  color: black; }

.buildings-commodities-images {
  display: flex;
  justify-content: space-evenly; }

.buildings-commodities-images img {
  padding: 4px;
  box-shadow: inset 0 0 0 14px white;
  margin: 7px 0px;
  border-radius: 50%; }

.buildings-boolean-image {
  text-align: center;
  padding-top: 4px; }

.buildings-boolean-image img {
  box-shadow: inset 0 0 0 14px white;
  padding: 0 2px;
  border-radius: 50%; }

.buildings-filters {
  width: 54%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .buildings-filters .buildings-filter-add {
    width: 25%;
    min-width: 120px;
    cursor: pointer;
    font-size: 18px;
    color: white;
    height: 40px;
    background: #98bf47;
    line-height: 40px;
    text-align: center;
    transition: 0.2s all ease; }
  .buildings-filters .buildings-filter-add:hover {
    background-color: #3C414B; }
  .buildings-filters .form-group .field {
    width: 60%; }
    .buildings-filters .form-group .field .form-control {
      font-size: 16px; }
  .buildings-filters .buildings-filter-list {
    margin: 15px auto 25px;
    min-width: 710px; }
  .buildings-filters .buildings-filtering {
    display: flex;
    padding: 0 10px;
    margin-top: 12px;
    border: 1px solid #eeedee; }
    .buildings-filters .buildings-filtering .buildings-filter-remove {
      padding: 8px;
      margin-top: 3px;
      font-size: 22px;
      line-height: 12px;
      cursor: pointer;
      color: #BA0C2F; }
    .buildings-filters .buildings-filtering .buildings-filter-verb {
      padding-top: 11px;
      width: 6%;
      min-width: 45px;
      text-align: center; }
