.tip-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 40px;
  margin: auto; }
  .tip-header .tip-title {
    font-size: 24px;
    line-height: 36px; }
  .tip-header .tip-buttons {
    display: flex;
    justify-content: space-between;
    width: 25%;
    min-width: 140px;
    color: white;
    font-size: 18px;
    text-align: center; }
  .tip-header .tip-cancel,
  .tip-header .tip-save {
    padding-top: 10px;
    height: 30px;
    width: 48%;
    border: #333;
    background-color: #3C414B;
    color: inherit;
    cursor: pointer;
    transition: all 0.2s ease-in-out; }
  .tip-header .tip-save {
    background-color: #98bf47; }
  .tip-header .tip-cancel:hover {
    background-color: #f05910; }
  .tip-header .tip-save:hover {
    background-color: #3C414B; }

.tip-error-link {
  padding: 0px 10px 20px; }
  .tip-error-link a {
    color: #4D688C;
    text-decoration: underline;
    font-style: italic; }

.tip-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px; }
  .tip-body div {
    width: fit-content; }
  .tip-body .tip-headline,
  .tip-body .tip-body-content,
  .tip-body .tip-link {
    width: 90%;
    min-width: 350px; }
  .tip-body .full-text-input {
    width: 90%; }
    .tip-body .full-text-input .form-group {
      width: 100%;
      justify-content: flex-start; }
      .tip-body .full-text-input .form-group label {
        width: 20%;
        min-width: 164px; }
      .tip-body .full-text-input .form-group .field {
        width: 80%; }

.form-group {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 40%;
  min-width: 350px; }
  .form-group label {
    width: 50%;
    margin-top: 2px; }
  .form-group .field {
    width: 44%;
    padding: 0 20px; }
    .form-group .field .form-control {
      width: 100%;
      min-width: 120px;
      font-size: 14px; }

.full-text-input {
  width: 90%; }
  .full-text-input .form-group {
    width: 100%;
    justify-content: flex-start; }
    .full-text-input .form-group label {
      width: 20%; }
    .full-text-input .form-group .field {
      width: 72%; }

.alert.alert-danger {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding-top: 4px;
  font-style: italic;
  color: #BA0C2F; }
