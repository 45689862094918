.energy-story .general-card {
  padding: 0;
  background: none;
  box-shadow: none; }

.energy-story .common-tabbed-navigation-view {
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15); }
