.building-tabs {
  padding-bottom: 42px;
  background-color: white; }

.building-tabs button {
  position: relative;
  background-color: #3C414B;
  float: left;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  border: 1px solid #ccc;
  font-size: 18px;
  color: white; }

.building-tabs button:hover {
  background-color: #98bf47; }

.building-tabs button.active {
  background-color: white;
  border-bottom: 1px solid white;
  border-collapse: collapse;
  z-index: 100;
  color: black; }

.building-tab-content {
  padding: 10px;
  display: none;
  border: 1px solid #ccc;
  z-index: 0; }

.eui-title {
  font-size: 18px;
  font-weight: 400; }

hr {
  width: 50%; }

.form-group {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 40%;
  min-width: 350px; }
  .form-group label {
    width: 50%;
    margin-top: 2px; }
  .form-group .field {
    width: 44%;
    padding: 0 20px; }
    .form-group .field .form-control {
      width: 100%;
      min-width: 120px;
      font-size: 14px; }

.full-text-input {
  width: 90%; }
  .full-text-input .form-group {
    width: 100%;
    justify-content: flex-start; }
    .full-text-input .form-group label {
      width: 20%; }
    .full-text-input .form-group .field {
      width: 72%; }

.alert.alert-danger {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding-top: 4px;
  font-style: italic;
  color: #BA0C2F; }

hr {
  margin: 20px auto; }

.building-tab-information {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px; }
  .building-tab-information div {
    width: fit-content; }

.building-tab-alias-names,
.building-tab-certifications,
.building-tab-building-keys {
  padding-bottom: 10px; }
  .building-tab-alias-names .building-tab-alias-names-header,
  .building-tab-alias-names .building-tab-certifications-header,
  .building-tab-alias-names .building-tab-building-keys-header,
  .building-tab-certifications .building-tab-alias-names-header,
  .building-tab-certifications .building-tab-certifications-header,
  .building-tab-certifications .building-tab-building-keys-header,
  .building-tab-building-keys .building-tab-alias-names-header,
  .building-tab-building-keys .building-tab-certifications-header,
  .building-tab-building-keys .building-tab-building-keys-header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px; }
    .building-tab-alias-names .building-tab-alias-names-header .building-tab-alias-names-title,
    .building-tab-alias-names .building-tab-alias-names-header .building-tab-certifications-title,
    .building-tab-alias-names .building-tab-alias-names-header .building-tab-building-keys-title,
    .building-tab-alias-names .building-tab-certifications-header .building-tab-alias-names-title,
    .building-tab-alias-names .building-tab-certifications-header .building-tab-certifications-title,
    .building-tab-alias-names .building-tab-certifications-header .building-tab-building-keys-title,
    .building-tab-alias-names .building-tab-building-keys-header .building-tab-alias-names-title,
    .building-tab-alias-names .building-tab-building-keys-header .building-tab-certifications-title,
    .building-tab-alias-names .building-tab-building-keys-header .building-tab-building-keys-title,
    .building-tab-certifications .building-tab-alias-names-header .building-tab-alias-names-title,
    .building-tab-certifications .building-tab-alias-names-header .building-tab-certifications-title,
    .building-tab-certifications .building-tab-alias-names-header .building-tab-building-keys-title,
    .building-tab-certifications .building-tab-certifications-header .building-tab-alias-names-title,
    .building-tab-certifications .building-tab-certifications-header .building-tab-certifications-title,
    .building-tab-certifications .building-tab-certifications-header .building-tab-building-keys-title,
    .building-tab-certifications .building-tab-building-keys-header .building-tab-alias-names-title,
    .building-tab-certifications .building-tab-building-keys-header .building-tab-certifications-title,
    .building-tab-certifications .building-tab-building-keys-header .building-tab-building-keys-title,
    .building-tab-building-keys .building-tab-alias-names-header .building-tab-alias-names-title,
    .building-tab-building-keys .building-tab-alias-names-header .building-tab-certifications-title,
    .building-tab-building-keys .building-tab-alias-names-header .building-tab-building-keys-title,
    .building-tab-building-keys .building-tab-certifications-header .building-tab-alias-names-title,
    .building-tab-building-keys .building-tab-certifications-header .building-tab-certifications-title,
    .building-tab-building-keys .building-tab-certifications-header .building-tab-building-keys-title,
    .building-tab-building-keys .building-tab-building-keys-header .building-tab-alias-names-title,
    .building-tab-building-keys .building-tab-building-keys-header .building-tab-certifications-title,
    .building-tab-building-keys .building-tab-building-keys-header .building-tab-building-keys-title {
      font-size: 18px;
      font-weight: 400;
      padding-top: 8px; }
    .building-tab-alias-names .building-tab-alias-names-header .building-tab-alias-names-add,
    .building-tab-alias-names .building-tab-alias-names-header .building-tab-certifications-add,
    .building-tab-alias-names .building-tab-alias-names-header .building-tab-building-keys-add,
    .building-tab-alias-names .building-tab-certifications-header .building-tab-alias-names-add,
    .building-tab-alias-names .building-tab-certifications-header .building-tab-certifications-add,
    .building-tab-alias-names .building-tab-certifications-header .building-tab-building-keys-add,
    .building-tab-alias-names .building-tab-building-keys-header .building-tab-alias-names-add,
    .building-tab-alias-names .building-tab-building-keys-header .building-tab-certifications-add,
    .building-tab-alias-names .building-tab-building-keys-header .building-tab-building-keys-add,
    .building-tab-certifications .building-tab-alias-names-header .building-tab-alias-names-add,
    .building-tab-certifications .building-tab-alias-names-header .building-tab-certifications-add,
    .building-tab-certifications .building-tab-alias-names-header .building-tab-building-keys-add,
    .building-tab-certifications .building-tab-certifications-header .building-tab-alias-names-add,
    .building-tab-certifications .building-tab-certifications-header .building-tab-certifications-add,
    .building-tab-certifications .building-tab-certifications-header .building-tab-building-keys-add,
    .building-tab-certifications .building-tab-building-keys-header .building-tab-alias-names-add,
    .building-tab-certifications .building-tab-building-keys-header .building-tab-certifications-add,
    .building-tab-certifications .building-tab-building-keys-header .building-tab-building-keys-add,
    .building-tab-building-keys .building-tab-alias-names-header .building-tab-alias-names-add,
    .building-tab-building-keys .building-tab-alias-names-header .building-tab-certifications-add,
    .building-tab-building-keys .building-tab-alias-names-header .building-tab-building-keys-add,
    .building-tab-building-keys .building-tab-certifications-header .building-tab-alias-names-add,
    .building-tab-building-keys .building-tab-certifications-header .building-tab-certifications-add,
    .building-tab-building-keys .building-tab-certifications-header .building-tab-building-keys-add,
    .building-tab-building-keys .building-tab-building-keys-header .building-tab-alias-names-add,
    .building-tab-building-keys .building-tab-building-keys-header .building-tab-certifications-add,
    .building-tab-building-keys .building-tab-building-keys-header .building-tab-building-keys-add {
      height: 40px;
      width: 15%;
      min-width: 140px;
      background-color: #98bf47;
      cursor: pointer;
      margin-right: 20px;
      font-size: 16px;
      color: white;
      line-height: 40px;
      text-align: center; }
  .building-tab-alias-names .building-tab-alias-name-list .no-alias,
  .building-tab-alias-names .building-tab-alias-name-list .no-keys,
  .building-tab-alias-names .building-tab-alias-name-list .no-certs,
  .building-tab-alias-names .building-tab-certification-list .no-alias,
  .building-tab-alias-names .building-tab-certification-list .no-keys,
  .building-tab-alias-names .building-tab-certification-list .no-certs,
  .building-tab-alias-names .building-tab-building-key-list .no-alias,
  .building-tab-alias-names .building-tab-building-key-list .no-keys,
  .building-tab-alias-names .building-tab-building-key-list .no-certs,
  .building-tab-certifications .building-tab-alias-name-list .no-alias,
  .building-tab-certifications .building-tab-alias-name-list .no-keys,
  .building-tab-certifications .building-tab-alias-name-list .no-certs,
  .building-tab-certifications .building-tab-certification-list .no-alias,
  .building-tab-certifications .building-tab-certification-list .no-keys,
  .building-tab-certifications .building-tab-certification-list .no-certs,
  .building-tab-certifications .building-tab-building-key-list .no-alias,
  .building-tab-certifications .building-tab-building-key-list .no-keys,
  .building-tab-certifications .building-tab-building-key-list .no-certs,
  .building-tab-building-keys .building-tab-alias-name-list .no-alias,
  .building-tab-building-keys .building-tab-alias-name-list .no-keys,
  .building-tab-building-keys .building-tab-alias-name-list .no-certs,
  .building-tab-building-keys .building-tab-certification-list .no-alias,
  .building-tab-building-keys .building-tab-certification-list .no-keys,
  .building-tab-building-keys .building-tab-certification-list .no-certs,
  .building-tab-building-keys .building-tab-building-key-list .no-alias,
  .building-tab-building-keys .building-tab-building-key-list .no-keys,
  .building-tab-building-keys .building-tab-building-key-list .no-certs {
    width: fit-content;
    margin: auto;
    color: #676767;
    font-style: italic; }
  .building-tab-alias-names .building-tab-alias-name-list .building-tab-alias-name,
  .building-tab-alias-names .building-tab-alias-name-list .building-tab-certification,
  .building-tab-alias-names .building-tab-alias-name-list .building-tab-building-key,
  .building-tab-alias-names .building-tab-certification-list .building-tab-alias-name,
  .building-tab-alias-names .building-tab-certification-list .building-tab-certification,
  .building-tab-alias-names .building-tab-certification-list .building-tab-building-key,
  .building-tab-alias-names .building-tab-building-key-list .building-tab-alias-name,
  .building-tab-alias-names .building-tab-building-key-list .building-tab-certification,
  .building-tab-alias-names .building-tab-building-key-list .building-tab-building-key,
  .building-tab-certifications .building-tab-alias-name-list .building-tab-alias-name,
  .building-tab-certifications .building-tab-alias-name-list .building-tab-certification,
  .building-tab-certifications .building-tab-alias-name-list .building-tab-building-key,
  .building-tab-certifications .building-tab-certification-list .building-tab-alias-name,
  .building-tab-certifications .building-tab-certification-list .building-tab-certification,
  .building-tab-certifications .building-tab-certification-list .building-tab-building-key,
  .building-tab-certifications .building-tab-building-key-list .building-tab-alias-name,
  .building-tab-certifications .building-tab-building-key-list .building-tab-certification,
  .building-tab-certifications .building-tab-building-key-list .building-tab-building-key,
  .building-tab-building-keys .building-tab-alias-name-list .building-tab-alias-name,
  .building-tab-building-keys .building-tab-alias-name-list .building-tab-certification,
  .building-tab-building-keys .building-tab-alias-name-list .building-tab-building-key,
  .building-tab-building-keys .building-tab-certification-list .building-tab-alias-name,
  .building-tab-building-keys .building-tab-certification-list .building-tab-certification,
  .building-tab-building-keys .building-tab-certification-list .building-tab-building-key,
  .building-tab-building-keys .building-tab-building-key-list .building-tab-alias-name,
  .building-tab-building-keys .building-tab-building-key-list .building-tab-certification,
  .building-tab-building-keys .building-tab-building-key-list .building-tab-building-key {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
    border: 1px solid #eeedee; }
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-alias-name-list .building-tab-building-key .building-tab-building-key-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-certification-list .building-tab-building-key .building-tab-building-key-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-alias-names .building-tab-building-key-list .building-tab-building-key .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-alias-name-list .building-tab-building-key .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-certification-list .building-tab-building-key .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-certifications .building-tab-building-key-list .building-tab-building-key .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-alias-name-list .building-tab-building-key .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-certification-list .building-tab-building-key .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-alias-name .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-alias-name .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-alias-name .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-certification .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-certification .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-certification .building-tab-building-key-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-building-key .building-tab-alias-name-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-building-key .building-tab-certifications-remove,
    .building-tab-building-keys .building-tab-building-key-list .building-tab-building-key .building-tab-building-key-remove {
      padding: 8px;
      margin-top: 3px;
      font-size: 22px;
      line-height: 12px;
      cursor: pointer;
      color: #BA0C2F; }
  .building-tab-alias-names .building-tab-building-key-list,
  .building-tab-certifications .building-tab-building-key-list,
  .building-tab-building-keys .building-tab-building-key-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 85%;
    margin: auto; }
  .building-tab-alias-names .building-tab-alias-name .full-text-input .form-group .field,
  .building-tab-certifications .building-tab-alias-name .full-text-input .form-group .field,
  .building-tab-building-keys .building-tab-alias-name .full-text-input .form-group .field {
    margin-left: -27px; }

.commodities-title {
  margin-bottom: 5px; }

.commodities-list {
  display: flex;
  flex-wrap: wrap; }
  .commodities-list .commodity-list-option {
    display: flex;
    padding: 6px; }
    .commodities-list .commodity-list-option .commodities-status {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 6px; }
      .commodities-list .commodity-list-option .commodities-status img {
        height: 100%;
        width: 100%; }

.commodities-validate-button,
.building-tab-unmetered-add {
  height: 30px;
  width: 20%;
  padding-top: 10px;
  margin: 0 0 10px auto;
  border: #333;
  background-color: #98bf47;
  color: white;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out; }

.commodities-validate-button:hover,
.building-tab-unmetered-add:hover {
  background-color: #3C414B; }

.building-tab-unmetered-add {
  margin-right: -10px; }

.building-tab-unmetered {
  padding-bottom: 10px; }
  .building-tab-unmetered .building-tab-unmetered-header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px; }
    .building-tab-unmetered .building-tab-unmetered-header .building-tab-unmetered-title {
      font-size: 18px;
      font-weight: 400;
      padding-top: 8px; }
  .building-tab-unmetered .building-tab-unmetered-list .no-certs {
    width: fit-content;
    margin: auto;
    color: #676767;
    font-style: italic; }
  .building-tab-unmetered .building-tab-unmetered-list .building-tab-unmetered {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
    border: 1px solid #eeedee;
    padding: 10px; }
    .building-tab-unmetered .building-tab-unmetered-list .building-tab-unmetered .building-tab-unmetered-remove {
      padding: 8px;
      margin-top: 3px;
      font-size: 22px;
      line-height: 12px;
      cursor: pointer;
      color: #BA0C2F; }
  .building-tab-unmetered .building-tab-unmetered-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 85%;
    margin: auto; }

.eui-title {
  margin-bottom: 5px; }

.eui-list {
  display: flex;
  flex-wrap: wrap; }
  .eui-list .eui-dropdown {
    display: flex;
    flex-direction: row;
    margin: 20px 20px 20px;
    justify-content: flex-start; }
    .eui-list .eui-dropdown .form-group {
      display: flex;
      justify-content: flex-start;
      padding-top: 0px; }
  .eui-list .eui-status {
    margin: 20px 20px 20px; }
    .eui-list .eui-status .Coming {
      color: #FFCC00; }
    .eui-list .eui-status .Ready {
      color: green; }
    .eui-list .eui-status .Unavailable {
      color: red; }
  .eui-list .eui-list-option {
    display: flex;
    padding: 6px; }
    .eui-list .eui-list-option .eui-status {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 6px; }
      .eui-list .eui-list-option .eui-status img {
        height: 100%;
        width: 100%; }

.eui-validate-button {
  height: 30px;
  width: 20%;
  padding-top: 10px;
  margin: 0 0 10px auto;
  border: #333;
  background-color: #98bf47;
  color: white;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out; }

.eui-validate-button:hover {
  background-color: #3C414B; }

.dropzone {
  height: 400px; }

.dropzone .dz-preview.dz-image-preview {
  background: #E1E1E1; }

.dz-image {
  width: 600px !important;
  height: 360px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #E1E1E1;
  border-radius: 0 !important; }

.dz-size {
  display: none; }

.eui-title {
  margin-bottom: 5px; }

.euiu-list {
  display: flex;
  flex-wrap: wrap; }
  .euiu-list .eui-list-option {
    display: flex;
    padding: 6px; }
    .euiu-list .eui-list-option .eui-status {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 6px; }
      .euiu-list .eui-list-option .eui-status img {
        height: 100%;
        width: 100%; }

.eui-validate-button,
.building-tab-unmetered-add {
  height: 30px;
  width: 20%;
  padding-top: 10px;
  margin: 0 0 10px auto;
  border: #333;
  background-color: #98bf47;
  color: white;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out; }

.eui-validate-button:hover,
.building-tab-unmetered-add:hover {
  background-color: #3C414B; }

.building-tab-unmetered-add {
  margin-right: -10px; }

.building-tab-unmetered {
  padding-bottom: 10px; }
  .building-tab-unmetered .building-tab-unmetered-header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px; }
    .building-tab-unmetered .building-tab-unmetered-header .building-tab-unmetered-title {
      font-size: 18px;
      font-weight: 400;
      padding-top: 8px; }
  .building-tab-unmetered .building-tab-unmetered-list .no-certs {
    width: fit-content;
    margin: auto;
    color: #676767;
    font-style: italic; }
  .building-tab-unmetered .building-tab-unmetered-list .building-tab-unmetered {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
    border: 1px solid #eeedee;
    padding: 10px; }
    .building-tab-unmetered .building-tab-unmetered-list .building-tab-unmetered .building-tab-unmetered-remove {
      padding: 8px;
      margin-top: 3px;
      font-size: 22px;
      line-height: 12px;
      cursor: pointer;
      color: #BA0C2F; }
  .building-tab-unmetered .building-tab-unmetered-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 85%;
    margin: auto; }
