.import-tip {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 30px 150px 0px 150px;
  font-size: 16px; }

.import-wrapper {
  padding: 35px; }
  .import-wrapper .import-button-false {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    width: 30%;
    height: 50px;
    min-width: 300px;
    color: white;
    font-size: 26px;
    padding-top: 20px;
    text-align: center;
    background-color: #98bf47; }
    .import-wrapper .import-button-false:hover {
      background-color: #3C414B;
      cursor: pointer; }
  .import-wrapper .import-button-true {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    width: 30%;
    height: 50px;
    min-width: 300px;
    color: white;
    font-size: 26px;
    padding-top: 20px;
    text-align: center;
    background-color: #3C414B;
    pointer-events: none; }
  .import-wrapper .import-list {
    justify-content: center;
    margin: 20px auto;
    width: 30%;
    height: 50px;
    min-width: 300px;
    font-size: 26px;
    padding-top: 20px;
    text-align: center;
    margin-bottom: 300px; }
  .import-wrapper .failed {
    background-color: red; }
  .import-wrapper .success {
    background-color: #98bf47; }
