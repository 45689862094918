.common-tabbed-navigation-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px; }
  .common-tabbed-navigation-tabs .common-button {
    margin: 0 8px; }
    .common-tabbed-navigation-tabs .common-button:first-of-type {
      margin-left: 0; }
