@font-face {
	font-family: 'Proxima Nova';
	src: url('ProximaNova-Bold.eot');
	src: url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Bold.woff2') format('woff2'),
		url('ProximaNova-Bold.woff') format('woff'),
		url('ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('ProximaNova-Regular.eot');
	src: url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Regular.woff2') format('woff2'),
		url('ProximaNova-Regular.woff') format('woff'),
		url('ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
