.common-table {
  overflow-x: scroll;
  width: 100%; }
  .common-table table {
    border-collapse: collapse;
    width: calc(100% - 1px);
    max-width: 100%; }
  .common-table thead {
    color: #3C414B;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: bold;
    line-height: 17px; }
    .common-table thead th {
      padding: 14px 10px;
      padding-top: 24px;
      text-align: left; }
  .common-table .common-table-editing-rows tbody tr {
    pointer-events: none;
    opacity: 0.5; }
  .common-table .common-table-editing-rows tbody.editing tr {
    pointer-events: all;
    opacity: 1; }
  .common-table tbody {
    color: #363636;
    font-family: "Proxima Nova";
    font-size: 14px;
    line-height: 27px; }
    .common-table tbody td {
      word-break: keep-all; }
      .common-table tbody td div {
        padding: 0 6px; }
    .common-table tbody input {
      color: #3C414B;
      font-family: "Proxima Nova";
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      text-align: right;
      margin: 20px 0;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #E0E0E0;
      padding: 4px 3px; }
      .common-table tbody input.invalid {
        color: red;
        border-color: red; }
    .common-table tbody.editable tr {
      cursor: text; }
  .common-table th,
  .common-table td {
    padding: 4px 10px;
    border: 1px solid rgba(229, 229, 229, 0.5);
    text-align: right; }
    .common-table th:first-of-type,
    .common-table td:first-of-type {
      padding-left: 20px;
      text-align: left; }
      .common-table th:first-of-type input,
      .common-table td:first-of-type input {
        text-align: left; }
    .common-table th:last-of-type,
    .common-table td:last-of-type {
      padding-right: 20px; }
  .common-table input::-webkit-inner-spin-button,
  .common-table input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.common-table-button-add,
.common-table-button-cancel,
.common-table-button-save {
  position: sticky;
  position: -webkit-sticky;
  color: #98BF47;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  padding: 16px;
  cursor: pointer;
  display: inline-block; }
  .common-table-button-add:hover,
  .common-table-button-cancel:hover,
  .common-table-button-save:hover {
    opacity: 0.9; }

.common-table-button-cancel {
  left: 20px; }

.common-table-button-save,
.common-table-button-add {
  float: right;
  right: 20px; }
