.logout-wrapper {
  position: absolute;
  top: 20px;
  right: 0;
  background-color: #3C414B;
  height: 80px;
  display: flex;
  align-items: center;
  width: 20%;
  min-width: 260px;
  justify-content: space-around;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease-in-out; }

.logout-wrapper:hover {
  background: #f05910; }

.logout-maintext {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 2px; }

.logout-subtext {
  font-size: 12px;
  font-style: italic; }

.exit-icon {
  height: 36px;
  width: 28px; }
