.tips-wrapper,
.tip-wrapper {
  padding: 35px; }

.tips-table,
.tip-layout {
  margin: 0 auto 65px;
  padding: 20px 35px 35px;
  background: white;
  max-width: 1024px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.12);
  text-align: left; }

.new-tip-button {
  width: 14%;
  margin-left: 86%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  height: 36px; }

.create-new-tip {
  width: 100%;
  background: #98bf47;
  line-height: 36px;
  text-align: center;
  transition: 0.3s; }

.create-new-tip:hover {
  background-color: #3C414B; }

.tips-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #333;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  border-bottom: #333 solid 2px; }

.tips-body {
  display: flex;
  flex-direction: column; }
  .tips-body .tips-none {
    margin: auto;
    padding-top: 20px;
    color: #676767;
    font-style: italic; }

.tips-table-row {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #eeedee;
  cursor: pointer;
  height: 45px; }

.tips-table-row:hover {
  background-color: #9C9D9F;
  transition: all 0.3s ease-in-out 0s; }

.tips-headline,
.tips-body-message,
.tips-link {
  width: 24%;
  margin: auto 10px;
  color: black; }

.tips-display-start {
  width: 16%;
  text-align: center; }

.tips-active {
  width: 11%;
  text-align: center; }

.tips-headline,
.tips-body-message,
.tips-link,
.tips-display-start,
.tips-active {
  margin: auto 0;
  text-decoration: none;
  color: black; }

.tips-boolean-image {
  text-align: center;
  padding-top: 4px; }

.tips-boolean-image img {
  box-shadow: inset 0 0 0 14px white;
  padding: 0 2px;
  border-radius: 50%; }
